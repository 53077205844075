import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=c49e6064&scoped=true"
import script from "./detail.vue?vue&type=script&lang=js"
export * from "./detail.vue?vue&type=script&lang=js"
import style0 from "./detail.vue?vue&type=style&index=0&id=c49e6064&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c49e6064",
  null
  
)

export default component.exports