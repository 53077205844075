<template>
  <div class="detail-page">
    <common-header></common-header>
    <div class="detail-content">
      <!-- banner图片 -->
      <div class="about-banner" :style="{'background':'url(' + $http + '/zh/detail/banner.jpg)'}">
        <img class="banner-font" :src="$http + '/zh/detail/bannerFont.png'" alt="图片文字" />
      </div>
      <!-- 弦乐 Qin String -->
      <div class="detail-product">
        <!-- 试看 -->
        <div class="detail-try-see">
          <div class="video-online">
            <div class='musci-video'>
              <img class="video-back" :src="nowVideo.pic" />
              <img class="video-play" @click="playVideo()" :src="$http + '/img/playBack.png'" />
              <div>{{nowVideo.name}}</div>
            </div>
          </div>
          <div class="detail-product-right">
            <img :src="$http + '/zh/detail/products.png'" />
            <div>Qin Strings 采样是在最大管弦乐录音棚“金田录音棚”进行采样的，采样用的编制86442，前期采样的时候就过了SSL调音台，做的比较温暖，更适合当下流行音乐。</div>
          </div>
        </div>
        <!-- 试听 -->
        <div class="detail-try-listen">
          <div class="music-audio">
            <musciAudio :nowmusic="nowmusic"></musciAudio>
          </div>
          <img class="musci-buy" @click="buyFunc" :src="$http + '/zh/home/Buy.png'" />
        </div>
      </div>
      <!-- 产品优势 -->
      <div class="detail-advantage" :style="{'background':'url(' + $http + '/zh/detail/microBack.png), black'}">
        <img class="advantage-title-img" :src="$http + '/zh/detail/advantage.png'" />
        <div class="advantage-info">
          <div class="advantage-info-left">
            <div class="info-left-title">拨奏、断奏采样出色，基本 0 延迟</div>
            <div class="info-left-text">
              <div>Qin Strings 弦乐音色，从根本上解决了延迟的问题，基本上0延迟，可以开着节拍器进行编曲和制作，听感上没有问题。</div>
              <div>左手的颤音，小二度大二度，通过CC4控制器，50以下小二度，50以上是大二度。拨奏采样、断奏采样，都非常出色。</div>
            </div>
          </div>
          <div class="advantage-info-right">
            <video-inner :nowmusic="caifangVideo" :heightStyle="'100%'" :widthStyle="'100%'"></video-inner>
          </div>
        </div>
        <div class="advantage-info advantage-info-special">
          <div class="advantage-info-right">
            <img class="one-img" :src="$http + '/zh/detail/keyword1.png'" />
            <div class="two-img-box">
              <img :src="$http + '/zh/detail/keyword2.png'" />
              <img :src="$http + '/zh/detail/keyword3.png'" />
            </div>
          </div>
          <div class="advantage-info-left">
            <div class="info-left-title">
              <span>与NI Komplete S系列键盘</span>
              <span>软硬件联动体验</span>
            </div>
            <div class="info-left-text">
              <div>Qin Strings 由NI官方进行封包制作，兼容NI全系列硬件产品，采用标准库的方式进行发售，用户购买后获得的是NI Access的原厂激活码。</div>
              <div>同时，在NI的S系列键盘上，您可以获得完美的使用体验，因为Qin Strings中的所有参数均可通过NI 的键盘进行调节。</div>
            </div>
          </div>
        </div>
        <div class="advantage-info advantage-info-special2">
          <div class="advantage-info-left">
            <div class="info-left-title">关于 Qin Strings 录制</div>
            <div class="info-left-text">
              <div>Qin Strings的录制是在金田录音棚，录音师王小四老师有着非常多的弦乐录制经验。</div>
              <div>在录制过程中，陈沁扬老师以制作人的身份全程监棚，不放过任何一个细节，力求完美。每一个采样都通过SSL调音台的放大，并在前期就进行了非常谨慎的EQ处理，保证在录音时得到极高质量的采样，对每一个连奏的采样进行细致的微调，还原弦乐连奏的真实感。</div>
            </div>
          </div>
          <div class="advantage-info-right">
            <img :src="$http + '/zh/detail/microphone.png'" alt="" />
          </div>
        </div>
        <div class="advantage-info advantage-info-special3">
          <div class="advantage-info-right">
            <video-inner :nowmusic="stringRubbingVideo" :heightStyle="'100%'" :widthStyle="'100%'"></video-inner>
          </div>
          <div class="advantage-info-left">
            <div class="info-left-title">连奏和揉弦表现出众</div>
            <div class="info-left-text">
              <div>揉弦使用CC11号控制器，Midi默认数值0是快速揉弦，数值25%-49%是慢速揉弦，数值50%-100%是不揉弦。</div>
              <div>CC4号控制器控制trill的小二度和大二度音程，Midi默认数值0是小二度trill，数值50%-100%是大二度trill。和其他弦乐音色一样，CC1是表情控制。</div></div>
          </div>
        </div>
      </div>
      <!--购买按钮  -->
      <div class="buy-now" v-if="scroll>=1500">
        <img class="process-back-buy" :style="{'top': '80vh'}" @click="buyFunc" :src="$http + '/zh/home/buyCol.png'" alt="">
      </div>
    </div>
    <common-footer></common-footer>
    <video-outside v-if="videoDialog" :nowmusic="nowVideo" @closeVideo='closeVideo'></video-outside>
    <order-online v-if="orderDialog" ref="orderDialog" @closeOnline='closeOnline'></order-online>
  </div>
</template>

<script>
import commonFooter from "../../components/zh/commonFooter.vue";
import commonHeader from "../../components/zh/commonHeader.vue";
import videoOutside from '../../components/videoOutside.vue'
import musciAudio from '../../components/musciAudio.vue'
import videoInner from '../../components/videoInner.vue'
// 在线订单组件
import orderOnline from '../../components/zh/orderOnline.vue'
export default {
  components: {
    commonHeader,
    commonFooter,
    musciAudio,
    videoOutside,
    videoInner,
    orderOnline
  },
  data(){
    return {
      videoDialog: false, // 视频弹窗dialo
      // 视频内容 -- 弦乐试看
      nowVideo: {
        link: this.$movie + '/mp4/chinese_style2.mp4',
        pic: this.$http + '/zh/detail/trySee.png',
        name: '《弦乐 试听》'
      },
      // 音频内容 -- 弦乐试听
      nowmusic: {
        link: require('../../assets/mp3/movie_audition.mp3'),
        pic: this.$http + '/zh/detail/tryListen.png',
        name: '电影音乐片段试听'
      },
      // 产品优势的采访
      caifangVideo: {
        link: this.$movie + '/mp4/caifang.mp4',
        pic: '',
        name: '',
        poster: this.$http + '/img/caifangBack.jpg'
      },
      // 联奏和揉弦视频
      stringRubbingVideo: {
        link: this.$movie + '/mp4/product_show.mp4',
        pic: '',
        name: '',
        poster: this.$http + '/img/product_showBack.jpg'
      },
      scroll: 0,
      scrollBottom: null,
      orderDialog: false, // 在线订单
    }
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll)
    if(this.$route.query.tradeNo){
      this.orderDialog = true
      // this.stopMove()
    }
  },
  destroyed(){
    window.removeEventListener('scroll',this.handleScroll)
  },
  methods: {
    // 监听滚动
    handleScroll(){
      // 变量 scrollHeight 是滚动条的总高度
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        // 变量 windowHeight 是可视区的高度
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        //或者使用document.querySelector('.class或者#id').scrollTop
        this.scroll = document.documentElement.scrollTop||document.body.scrollTop
        this.scrollBottom = scrollHeight - windowHeight - this.scroll
    },
    // 播放视频
    playVideo(){
      this.videoDialog = true
      // this.stopMove()
    },
    // 关闭视频
    closeVideo(){
      this.videoDialog = false
      // this.Move()
    },
    // 立即购买
    buyFunc(){
      this.orderDialog = true
      // this.stopMove()
    },
    closeOnline(){
      this.orderDialog = false
      // this.Move()
      if(this.$route.query.tradeNo){
        this.$router.push({
          path: this.$route.path
        })
      }
    },
    //停止页面滚动
    stopMove(){
      let m = function(e){e.preventDefault();};
      document.body.style.overflow='hidden';
      document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
    },
    //开启页面滚动
    Move(){
      let m =function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",m,{ passive:true });
    }
  }
}
</script>

<style scoped lang='scss'>
.detail-page{
  width: 100%;
  height: 100%;
  .detail-content{
    width: 100%;
    height: 100%;
    // 顶部banner
    .about-banner {
      padding-top: 90px;
      width: 100%;
      height: 814px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      
      .banner-font {
        width: 1157px;
        height: 241px;
        margin-top: 235px;
      }
    }
    // 弦乐 Qin Strings
    .detail-product{
      width: 100%;
      padding: 80px 251px 127px 251px;
      box-sizing: border-box;
      background: #000;
      .detail-try-see{
        display: flex;
        justify-content: space-between;
        .video-online{
          width: 697px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;
          .musci-video{
            position: relative;
            width: 697px;
            height: 389px;
            margin-bottom: 80px;
            .video-back{
              width: 100%;
              height: 419px;
            }
            .video-play{
              position: absolute;
              left: 50%;
              top: 50%;
              width: 60px;
              height: 60px;
              transform: translate(-50%, -50%);
              cursor: pointer;
            }
            div{
              margin-top: 6px;
              height: 33px;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
              line-height: 33px;
              text-align: center;
            }
          }
        }
        .detail-product-right{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          div{
            margin: 0px 0 0 46px;
            width: 550px;
            height: 216px;
            font-size: 24px;
            font-family: PingFang SC;
            color: rgba(255,255,255,.8);
            line-height: 54px;
            text-indent: 2em;
          }
          img{
            width: 597px;
            height: 153px;
          }
        }
      }
      .detail-try-listen{
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .music-audio{
          width: 697px;
          height: 235px;
        }
        .musci-buy{
          width: 264px;
          height: 70px;
          margin-bottom: 33px;
          cursor: pointer;
        }
      }
    }
    // 产品优势
    .detail-advantage{
      width: 100%;
      padding: 0 252px 0 253px;
      box-sizing: border-box;
      background-repeat: no-repeat !important;
      background-size: 100% 735px !important;
      background-position-y: 1540px !important;
      .advantage-title-img{
        margin-left: 21px;
        width: 668px;
        height: 153px;
      }
      .advantage-info{
        margin-top: 99px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .advantage-info-left{
          .info-left-title{
            width: 422px;
            height: 33px;
            font-size: 28px;
            color: #fff;
            line-height: 33px;
            font-family: Source-KeynoteartHans;
          }
          .info-left-text{
            margin-top: 60px;
            width: 578px;
            height: 324px;
            font-size: 24px;
            font-family: PingFang SC;
            color: rgba(255,255,255,.8);
            line-height: 54px;
            text-indent: 2em;
          }
        }
        .advantage-info-right{
          width: 740px;
          height: 377px;
          margin-bottom: 12px;
        }
      }
      .advantage-info-special{
        margin-top: 125px;
        padding-bottom: 131px;
        .advantage-info-right{
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 840px;
          .one-img{
            width: 840px;
            height: 420px;
            margin-bottom: 24px;
          }
          .two-img-box{
            width: 840px;
            height: 202px;
            display: flex;
            justify-content: space-between;
            img{
              width: 404px;
              height: 202px;
            }
          }
        }
        .advantage-info-left{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          height: 649px;
          .info-left-title{
            width: 552px;
            height: 88px;
            text-align: right;
            span{
              display: block;
            }
          }
          .info-left-text{
            width: 440px;
            height: 378px;
          }
        }
      }
      .advantage-info-special2{
        margin-top: 77px;
        padding-bottom: 174px;
        .advantage-info-left{
          .info-left-title{
            width: 288px;
            height: 33px;
          }
          .info-left-text{
            width: 627px;
            height: 378px;
          }
        }
        .advantage-info-right{
          width: 645px;
          height: 473px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .advantage-info-special3{
        margin-top: 127px;
        padding-bottom: 79px;
        .advantage-info-right{
          width: 651px;
          height: 435px;
        }
        .advantage-info-left{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          height: 435px;
          .info-left-title{
            width: 264px;
            height: 33px;
            text-align: right;
            span{
              display: block;
            }
          }
          .info-left-text{
            width: 552px;
            height: 324px;
            word-break:break-all;
          }
        }
      }
    }
    // 立即购买
    .process-back-buy{
      position: fixed;
      right: 42px;
      // bottom: 80px;
      width: 70px;
      height: 70px;
      cursor: pointer;
      z-index: 888;
    }
  }
}
</style>